import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { RequireAuth, SignIn } from 'authentication';
import Signin from 'authentication/SignIn';
import { roles } from 'enums';
import CircularLoadingFullPage from 'components/CircularLoadingFullPage';

const Devices = lazy(() => import('pages/devices'));
const DeviceDetails = lazy(() => import('pages/device-details'));
const Dashboard = lazy(() => import('pages/dashboard'));
const Users = lazy(() => import('pages/users'));
const ModuleTester = lazy(() => import('pages/module-tester'));
const ModuleMatcher = lazy(() => import('pages/module-matcher'));
const FullDeviceTester = lazy(() => import('pages/full-device-tester'));
const Packaging = lazy(() => import('pages/packaging-tester'));
const ShippingRequests = lazy(() => import('pages/shipping-requests'));
const CreateShippingRequest = lazy(() => import('pages/create-shipping-request'));
const ShippingRequestDetails = lazy(() => import('pages/shipping-request-details'));
const MicsSpeakersAnalytics = lazy(() => import('pages/mics-speakers-analytics'));
const GeneralConfigurations = lazy(() => import('pages/general-configurations'));
const ConfirmEmail = lazy(() => import('pages/confirm-email'));
const MicCalibrationTest = lazy(() => import('pages/mic-calibration-tests'));
const TestingApps = lazy(() => import('pages/testing-apps'));
const TestingAppDetails = lazy(() => import('pages/testing-apps-details'));
const TfsReleases = lazy(() => import('pages/tfs-releases'));
const DeviceTestingHistory = lazy(() => import('pages/device-testing-history'));
const WatsReports = lazy(() => import('pages/wats-reports'));
const AppModuleConfigurations = lazy(() => import('pages/app-module-configurations'));
const DeviceDefinitions = lazy(() => import('pages/device-definitions'));
const FactoryFiles = lazy(() => import('pages/factory-files'));
const ShippingDevices = lazy(() => import('pages/shipping-devices'));
const AudioModuleTester = lazy(() => import('pages/audio-module-tester'));
const ManufacturingClients = lazy(() => import('pages/manufacturing-clients'));
const DeviceStockManagement = lazy(() => import('pages/device-stock-management'));
const ShippingOrderManagement = lazy(() => import('pages/shipping-order-management'));
const CreateShippingOrder = lazy(() => import('pages/shipping-order-management/create-shipping-order'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<CircularLoadingFullPage />}>
      <Routes>
        <Route path='/account/confirmemail' element={<ConfirmEmail />} />
        <Route
          element={
            <RequireAuth
              roles={[
                roles.SUPERUSER,
                roles.MANUFACTURING_TESTER,
                roles.MANUFACTURING_COORDINATOR,
                roles.MANUFACTURING_ADMIN,
                roles.FACTORY_LOGISTICS,
                roles.SOLABORATE_LOGISTICS,
              ]}
            />
          }>
          <Route path='/' element={<SignIn />} />
        </Route>
        <Route element={<RequireAuth roles={[roles.SUPERUSER, roles.MANUFACTURING_ADMIN]} />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/users' element={<Users />} />
          <Route path='/general-configurations' element={<GeneralConfigurations />} />
          <Route path='/testing-apps' element={<TestingApps />} />
          <Route path='/testing-apps/details/:appId' element={<TestingAppDetails />} />
          <Route path='/tfs-releases' element={<TfsReleases />} />
          <Route path='/app-module-configurations' element={<AppModuleConfigurations />} />
          <Route path='/device-definitions' element={<DeviceDefinitions />} />
          <Route path='/shipping-devices' element={<ShippingDevices />} />
          <Route path='/manufacturing-clients' element={<ManufacturingClients />} />
        </Route>
        <Route
          element={
            <RequireAuth
              roles={[roles.SUPERUSER, roles.MANUFACTURING_TESTER, roles.MANUFACTURING_COORDINATOR, roles.MANUFACTURING_ADMIN]}
            />
          }>
          <Route path='/analytics/mics-speakers' element={<MicsSpeakersAnalytics />} />
          <Route path='/analytics/mics-speakers/:bookmarkId/link' element={<MicsSpeakersAnalytics />} />
          <Route path='/devices' element={<Devices />} />
          <Route path='/devices/:deviceId' element={<DeviceDetails />} />
          <Route path='/mic-calibration-tests' element={<MicCalibrationTest />} />
          <Route path='/module-tester' element={<ModuleTester />} />
          <Route path='/module-matcher' element={<ModuleMatcher />} />
          <Route path='/full-device-tester' element={<FullDeviceTester />} />
          <Route path='/packaging' element={<Packaging />} />
          <Route path='/audio-testing' element={<AudioModuleTester />} />
          <Route path='/devices/device-testing-history/:deviceFactoryId/:deviceFamilyId' element={<DeviceTestingHistory />} />
          <Route
            path='/devices/device-testing-history/:deviceId/:deviceFactoryId/:deviceFamilyId/details'
            element={<DeviceDetails />}
          />
          <Route path='/wats-reports' element={<WatsReports />} />
          <Route path='/factory-files' element={<FactoryFiles />} />
        </Route>
        <Route
          element={
            <RequireAuth
              roles={[roles.SUPERUSER, roles.MANUFACTURING_ADMIN, roles.FACTORY_LOGISTICS, roles.SOLABORATE_LOGISTICS]}
            />
          }>
          <Route path='/device-stock-management' element={<DeviceStockManagement />} />
          <Route path='/shipping-order-management/:orderId?' element={<ShippingOrderManagement />} />
          <Route path='/shipping-order-management/create' element={<CreateShippingOrder />} />
        </Route>
        <Route element={<RequireAuth roles={[roles.SUPERUSER, roles.MANUFACTURING_COORDINATOR, roles.MANUFACTURING_ADMIN]} />}>
          <Route path='/shipping-requests' element={<ShippingRequests />} />
          <Route path='/shipping-requests/:requestId/details' element={<ShippingRequestDetails />} />
          <Route path='/shipping-requests/create' element={<CreateShippingRequest />} />
          {/* <Route path='/shipping-order-management' element={<ShippingOrderManagement />} /> */}
        </Route>
        <Route path='/signin-oidc' element={<Signin />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
